import React from "react";
import { Link } from "react-router-dom";
import "./SignUp.css";

const SignUp = () => {
  return (
    <>
      <div
        className="container-fluid bg-container-color d-flex align-items-center justify-content-center"
        style={{ height: "90vh" }}
      >
        <div className="row border bg-signup p-sm-5 py-2 shadow">
          <h1
            className="text-center mb-4 text-light"
            style={{ letterSpacing: "5px", textDecoration: "underline" }}
          >
            Signup
          </h1>
          <div className="col-12">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
              />
              <label htmlFor="floatingInput">Name</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
              />
              <label htmlFor="floatingPassword">Email</label>
            </div>
            <div className="col-12">
              <div className="form-floating mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                />
                <label htmlFor="floatingInput">Password</label>
              </div>
              <div className="form-floating">
                <input
                  type="cPassword"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="cPassword"
                />
                <label htmlFor="floatingPassword">Conform Password</label>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <Link to="/login">
                <button className="btn btn-color me-2 shadow-none">
                  Signup
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
