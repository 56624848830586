import React, {useState, useEffect} from "react";

const BackToTop = () => {
  const [backtoTop, setBacktoTop] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 200 ? setBacktoTop(true) : setBacktoTop(false);
    });
  }, []);
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {backtoTop && (
        <button
          style={{
            position: "fixed",
            bottom: "7vh",
            right: "2.5rem",
            fontSize: "40px",
            background: "none",
            color: "#FF1111",
          }}
          type="button"
          onClick={() => scrollUp()}
          className="border-0"
        >
          <i className="bi bi-arrow-up-circle"></i>
        </button>
      )}
    </>
  );
};

export default BackToTop;
