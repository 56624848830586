import React from 'react'
import "./About.js";
import aboutImg from "../images/lab-2.jpg";

const AboutHome = () => {
    return (
        <>
       <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div
              className="col-lg-6 order-2 order-lg-2"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <img src={aboutImg} className="img-fluid" alt="" />
            </div>
            <div className="col-lg-6 order-1 order-lg-1">
              {/* <p>
                We are a group of experienced professionals (Doctors, Engineers,
                HSE, IT, Company Secretary & Management Experts) who have come
                together to build a client-centric institute.</p><p> We take pride in
                our ability to provide quality services. Our mission is to
                achieve excellence at an affordable cost to make your dream come
                true. By providing quality education and imparting training,
                education, research with the sole aim to ameliorate standards in
                the healthcare, management, and other technical sectors.
              </p> */}
              <h1 className="pt-lg-4">
                Welcome to{" "}
                <span style={{ color: "#36447B", textDecoration: "underline" }}>
                  MITMS
                </span>
              </h1>
              <div className='py-4'>
              <p className="fst-italic pb-3">
                We are a group of experienced professionals (Doctors, Engineers,
                HSE, IT, Company Secretary & Management Experts) who have come
                together to build a client-centric institute.
              </p>
              <ul>
                <li>
                  <i className="bi bi-check-circle"></i> We take pride in our
                  ability to provide quality services.
                </li>
                <li>
                  <i className="bi bi-check-circle"></i> Our mission is to
                  achieve excellence at an affordable cost to make your dream
                  come true.
                </li>
                {/* <li><i className="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li> */}
              </ul>
              <p>
                By providing quality education and imparting training,
                education, research with the sole aim to ameliorate standards in
                the healthcare, management, and other technical sectors.{" "}
              </p>
              </div>
            </div>
          </div>
        </div>
      </section>
   
        </>
    )
}

export default AboutHome
