import React from "react";
import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import autoPlayApi from "./autoPlayApi";

const AutoPlay = () => {
  const [slideImg] = useState(autoPlayApi);
  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: "linear",
    arrows: false,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            // dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };

  return (
    <>
      <div className="container mt-3">
        <h2 className="py-4" style={{ color: "#36447B" }}>Events</h2>
        <Slider {...settings}>
          {slideImg.map((curElem) => {
            return (
              <div key={curElem.id} className="px-1">
                <img style={{height:"32vh"}} src={curElem.image} className="img-fluid" alt={curElem.name} />
                <p style={{fontSize:"0.75rem"}}>
                 {curElem.disc}
                 </p>
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default AutoPlay;
