import React from "react";
import { useState } from "react";
import "./Contact.css";
import axios from "axios";

const Contact = ({showAlert, curUser}) => {
  const [inputData, setInputData] = useState({
    name: "",
    mobile: "",
    email: "",
    course: "",
    address: "",
    state: "",
    textArea: "",
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputData({ ...inputData, [name]: value });
  };
  const handleSubmit = (e) => {
    const { name, mobile, email, course, address, state, textArea } = inputData;
    e.preventDefault();
    axios
      .post("https://mitms.in/api/Enquiry/PostCustEnquiry", {
        CustomerName: name,
        CustRef:curUser,
        MobileNumber: mobile,
        EmailId: email,
        Course: course,
        Address: address,
        State: state,
        Comments: textArea,
        fatherName: "",
        district: "",
        fIncome: "",
        stream: "",
        qualification: "",
      })
      .then(function (response) {
        if (response.status === 200) {
          showAlert("Someone will get in touch with you soon. 👌", "success");
        } else {
          showAlert("Something is wrong.", "danger");
        }
      })
      .catch(function (error) {
        showAlert(error, "danger");
      });
    setInputData({
      name: "",
      mobile: "",
      email: "",
      course: "",
      address: "",
      state: "",
      textArea: "",
    });
  };
  return (
    <>
      <div className="breadcrumbs">
        <div className="container">
          <h2>Contact Us</h2>
          <p>The cure for boredom is curiosty</p>
        </div>
      </div>

      <div className="container about-bg my-4 pb-5">
        <form onSubmit={handleSubmit} className="row g-3 py-4">
          <div className="col-md-4">
            <label htmlFor="validationDefault01" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="validationDefault01"
              name="name"
              onChange={handleChange}
              value={inputData.name}
              autoComplete="off"
              required
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="validationDefault02" className="form-label">
              Mobile number
            </label>
            <input
              type="tel"
              className="form-control"
              id="validationDefault02"
              name="mobile"
              onChange={handleChange}
              value={inputData.mobile}
              autoComplete="off"
              required
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="validationDefaultUsername" className="form-label">
              Email id
            </label>
            <div className="input-group">
              <span className="input-group-text" id="inputGroupPrepend2">
                @
              </span>
              <input
                name="email"
                onChange={handleChange}
                value={inputData.email}
                autoComplete="off"
                type="email"
                className="form-control"
                id="validationDefaultUsername"
                aria-describedby="inputGroupPrepend2"
                required
              />
            </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="validationDefault04" className="form-label">
              Select course
            </label>
            <select
              className="form-select"
              id="validationDefault04"
              name="course"
              onChange={handleChange}
              value={inputData.course}
              autoComplete="off"
              required
            >
              <option defaultValue disabled value="">
                Choose...
              </option>
              <option>Diploma</option>
              <option>Bachelor</option>
              <option>Masters</option>
              <option>HSE</option>
              <option>Bihar Government approved courses</option>
            </select>
          </div>
          <div className="col-md-5">
            <label htmlFor="validationDefault01" className="form-label">
              Address
            </label>
            <input
              type="text"
              className="form-control"
              id="validationDefault01"
              name="address"
              onChange={handleChange}
              value={inputData.address}
              autoComplete="off"
              required
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="validationDefault04" className="form-label">
              State
            </label>
            <select
              className="form-select"
              id="validationDefault04"
              name="state"
              onChange={handleChange}
              value={inputData.state}
              autoComplete="off"
              required
            >
              <option defaultValue disabled value="">
                Choose...
              </option>
              <option>Delhi</option>
              <option>Bihar</option>
              <option>Uttar Pradesh</option>
              <option>Haryana</option>
              <option>Rajsthan</option>
              <option>Punjab</option>
            </select>
          </div>
          <div className="col-12">
            <div className="mb-3">
              <label htmlFor="validationTextarea" className="form-label">
                Comments
              </label>
              <textarea
                className="form-control"
                name="textArea"
                onChange={handleChange}
                value={inputData.textArea}
                autoComplete="off"
                id="validationTextarea"
                placeholder="Remarks"
              ></textarea>
              <div className="invalid-feedback">
                Please enter a message in the textarea.
              </div>
            </div>
          </div>
          <div className="col-12 text-center">
            <button className="btn btn-success" type="submit">
              Submit form
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Contact;
