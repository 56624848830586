import paramedical from "../images/paramedical.jpg";
import management from "../images/management.jpg";
import HealthSafety from "../images/hse.jpeg";
import "./Popularcourse.css";

const Popularcourse = (props) => {
  const { handleShow } = props;
  return (
    <>
      <div className="container mb-5">
        <h1 className="my-4" style={{ color: "#36447B" }}>
          Popular Courses
        </h1>
        <div className="row my-2 d-flex">
          {/* <div className='col-md-6'><h3 className='my-3'><u><em>Medical Courses</em></u></h3></div> */}
          {/* <div className='col-md-6 d-none d-md-block'><h3 className='my-3'><u><em>Non-Medical Courses</em></u></h3></div> */}
        </div>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card h-100">
              <img src={paramedical} className="card-img-top" alt="course" />
              <div className="card-body">
                <h5 className="card-title">
                  Paramedical (U.G.C and Bihar Govt. approved)
                </h5>
                <button
                  onClick={handleShow}
                  type="button"
                  className="btn shadow-none mt-1 know-more"
                >
                  Know More
                </button>
              </div>
            </div>
          </div>
          {/* <div className='col-md-6 d-md-none'><h3 className='my-3'><u><em>Non-Medical Courses</em></u></h3></div> */}
          <div className="col">
            <div className="card h-100">
              <img src={management} className="card-img-top" alt="course" />
              <div className="card-body">
                <h5 className="card-title">Management</h5>
                <button
                  onClick={handleShow}
                  type="button"
                  className="btn shadow-none mt-1 know-more"
                >
                  Know More
                </button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={HealthSafety} className="card-img-top" alt="course" />
              <div className="card-body">
                <h5 className="card-title">Health Safety and Environment</h5>
                <button
                  onClick={handleShow}
                  type="button"
                  className="btn shadow-none mt-1 know-more"
                >
                  Know More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popularcourse;
