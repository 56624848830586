import React, { useEffect, useState } from "react";
import AdminScholarship from "./AdminScholarship";
import axios from "axios";
import exportFromJSON from 'export-from-json'

const Admin = ({showAlert, custid, setCustid}) => {
  const [userData, setUserData] = useState([]);
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false)
  const getUser = async () => {
    setLoader(true)
    try {
      const response = await axios.get(
        `https://mitms.in/api/Enquiry/GetEnquiryDetails?CustRef=${custid}`
      );
      // console.log(response.data);
      setUserData(response.data);
      setData(response.data)
      setLoader(false)
    } catch (error) {
      console.error(error);
      showAlert(error, "danger");
      setLoader(false)
    }
  };
  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, [custid]);
  const fileName = 'download'  
const exportType = 'xls'  
  const downloadFile = () => {  
    exportFromJSON({ data, fileName, exportType })  
  }
  return (
    <>
      <div className="container my-4" style={{ minHeight: "80vh" }}>
      {
      loader === true ? <h3 className="text-center pt-5" >Loading...</h3> : 
      <>
      <div className="row w-auto d-flex justify-content-end pb-3">
      <div className="d-flex justify-content-between">
      <button onClick={() => setCustid("")} type="button" className="btn btn-secondary w-auto me-3">All</button>
      <button onClick={(e) => downloadFile(e)} type="button" className="btn btn-secondary w-auto me-3">Download file</button>
      </div>
        </div>
        <div className="table-responsive">
          <table className="table table-light table-hover">
            <thead className="fs-5 align-middle">
              <tr style={{ fontSize: "0.9rem" }}>
                <th scope="col">Sn.</th>
                <th scope="col">Ref.</th>
                <th scope="col">Name</th>
                <th scope="col">Father Name</th>
                <th scope="col">Email</th>
                <th scope="col">District</th>
                <th scope="col">Mobile No.</th>
                <th scope="col">Course</th>
                <th scope="col">qualification</th>
                <th scope="col">10+2</th>
                <th scope="col">Address</th>
                <th scope="col">State</th>
                <th scope="col">Date and Time</th>
              </tr>
            </thead>
            { userData.map((curElem, i) => {
              return <AdminScholarship key={i} curElem={curElem} i={i} setCustid={setCustid} />
            })}
          </table>
        </div>

      </>
    }

      </div>
    </>
  );
};

export default Admin;
