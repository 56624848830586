import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import "./Navbar.css";
import logo32 from "../images/logo32.png";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Marquee from "react-fast-marquee";

const Navbar = ({ login, setLogin, showAlert, curUser, setCurUser }) => {
  const history = useHistory();
  const [loginLoading, setLoginLoading] = useState(false)
  // register
  const [showUp, setShowUp] = useState(false);

  const handleCloseUp = () => setShowUp(false);
  const handleShowUp = () => setShowUp(true);
  const [inputRegisterData, setInputRegisterData] = useState({
    name: "",
    MobileNumber:"",
    EmailId:"",
    UserType:"",
    userId: "",
    password: ""
  });
  const handleRegisterChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputRegisterData({ ...inputRegisterData, [name]: value });
  };
  const handleRegisterSubmit = (e) => {
    const { name, MobileNumber, EmailId, UserType, userId, password } = inputRegisterData;
    e.preventDefault();
    axios
      .post("/api/User/CustRegistrations", {
        Name: name,
        MobileNumber: MobileNumber,
        EmailId: EmailId,
        userId: userId,
        password: password,
        UserType: UserType
      })
      .then(function (response) {
        if (response.status === 200) {
          showAlert("User Register Successfully 👌", "success");
          handleCloseUp();
        } else {
          showAlert("Someone is wrong.", "danger");
          handleCloseUp();
        }
      })
      .catch(function (error) {
        alert(error);
        handleCloseUp();
      });
    setInputRegisterData({
      name: "",
    MobileNumber:"",
    EmailId:"",
    UserType:"",
    userId: "",
    password: ""
    });
  };

  // login
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (login === true) {
      setLogin(false);
      showAlert("You Are Logout Successfully.", "warning");
      history.push("/");
    } else {
      setShow(true);
    }
  };
  const [inputLoginData, setInputLoginData] = useState({
    userId: "",
    password: "",
  });
  const handleLoginChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputLoginData({ ...inputLoginData, [name]: value });
  };
  const handleLoginSubmit = (e) => {
    setLoginLoading(true)
    e.preventDefault();
    const { userId, password } = inputLoginData;
    axios
      .post(`/api/Enquiry/Login?userId=${userId}&password=${password}`, {})
      .then(function (response) {
        if (response.status === 200 && response.data[0].UserType === "Admin") {
          showAlert("You Are Login Successfully.👍", "success");
          setLoginLoading(false)
          setLogin(true);
          handleClose();
          setInputLoginData({
            userId: "",
            password: "",
          });
          history.push("/admin");
          setCurUser(response.data[0].UserType)
        } else if(response.status === 200 && response.data[0].UserType === "Associate"){
          showAlert("You Are Login Successfully.👍", "success");
          setLoginLoading(false)
          setLogin(true);
          handleClose();
          setInputLoginData({
            userId: "",
            password: "",
          });
          history.push("/student")
          setCurUser(response.data[0].UserType)
        } else {
          setLoginLoading(false)
          setInputLoginData({
            userId: "",
            password: "",
          });
          setLogin(false);
        }
      })
      .catch(function (error) {
        showAlert(error.message, "danger");
        setLoginLoading(false)
        setInputLoginData({
          userId: "",
          password: "",
        });
        handleClose();
      });
  };

  const location = useLocation();
  useEffect(() => {}, [location]);
  return (
    <>
      <nav
        className={`sticky-top navbar navbar-expand-lg navbar-light bg-white fw-bold py-sm-2 py-0 border-bottom`}
        id="navbar-color"
      >
        <div className="container">
          <Link to="/">
            <img
              src={logo32}
              alt="logo"
              className="img-fluid"
              style={{ width: "80px", height: "60px" }}
            />
          </Link>
          <button
            className="navbar-toggler shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item show-location d-flex justify-content-center align-items-center px-xlg-2">
                <Link
                  className={`nav-link show-location-link ${
                    location.pathname === "/" ? "show-location-link-active" : ""
                  }`}
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item show-location d-flex justify-content-center align-items-center px-xlg-2">
                <Link
                  className={`nav-link show-location-link ${
                    location.pathname === "/about"
                      ? "show-location-link-active"
                      : ""
                  }`}
                  to="/about"
                >
                  About
                </Link>
              </li>
              <li className="nav-item show-location d-flex justify-content-center align-items-center px-xlg-2">
                <Link
                  className={`nav-link show-location-link ${
                    location.pathname === "/courses"
                      ? "show-location-link-active"
                      : ""
                  }`}
                  to="/courses"
                >
                  Courses
                </Link>
              </li>
              {/* <li className="nav-item d-flex justify-content-center align-items-center px-xlg-2">
                  <Link className="nav-link" to="/scholarship">Scholarship</Link>
                </li> */}
              {/* <li className="nav-item d-flex justify-content-center align-items-center px-xlg-2">
                  <Link className="nav-link" to="/admission">Admission</Link>
                </li> */}
              <li className="nav-item show-location d-flex justify-content-center align-items-center px-xlg-2">
                <Link
                  className={`nav-link show-location-link ${
                    location.pathname === "/offers"
                      ? "show-location-link-active"
                      : ""
                  }`}
                  to="/offers"
                >
                  Offers
                </Link>
              </li>
              <li className="nav-item show-location d-flex justify-content-center align-items-center px-xlg-2">
                <Link
                  className={`nav-link show-location-link ${
                    location.pathname === "/gallery"
                      ? "show-location-link-active"
                      : ""
                  }`}
                  to="/gallery"
                >
                  Gallery
                </Link>
              </li>
              <li className="nav-item show-location d-flex justify-content-center align-items-center px-xlg-2">
                <Link
                  className={`nav-link show-location-link ${
                    location.pathname === "/contact"
                      ? "show-location-link-active"
                      : ""
                  }`}
                  to="/contact"
                >
                  Contact us
                </Link>
              </li>
              <li
                className={`${
                  curUser === "Admin" && login === true ? "" : "d-none"
                } nav-item show-location d-flex justify-content-center align-items-center px-xlg-2`}
              >
                <Link
                  className={`nav-link show-location-link ${
                    location.pathname === "/admin"
                      ? "show-location-link-active"
                      : ""
                  }`}
                  to="/admin"
                >
                  Dashboard
                </Link>
              </li>
              <li
                className={`${
                  curUser === "Associate" && login === true ? "" : "d-none"
                } nav-item show-location d-flex justify-content-center align-items-center px-xlg-2`}
              >
                <Link
                  className={`nav-link show-location-link ${
                    location.pathname === "/student"
                      ? "show-location-link-active"
                      : ""
                  }`}
                  to="/student"
                >
                  Student
                </Link>
              </li>
            </ul>
            <div className="d-flex justify-content-center align-items-center px-xlg-2">
              <li
                onClick={handleShowUp}
                className={`nav-link ${curUser === "Admin" && login === true ? "" : "d-none"}`}
                style={{ cursor: "pointer" }}
              >
                <span className="fw-bold get-started-btn mx-0">Register</span>
              </li>

              <li
                onClick={handleShow}
                className="nav-link"
                style={{ cursor: "pointer" }}
              >
                <span
                  className={`fw-bold get-started-btn mx-0 ${
                    login === true ? "text-danger" : ""
                  }`}
                >
                  {login === true ? "Logout" : "Login"}
                </span>
              </li>
            </div>
          </div>
        </div>
      </nav>
      <marquee width="100%" direction="left" height="30px" onmouseover="this.stop();" onmouseout="this.start();">
      <Link 
                  to="/offers"
                  style={{ color: "#f00707", cursor:"pointer" }}>
                  Aisha Scholarship (Scholarship up to 75% on limited seats)
                </Link>
                
        {/* <a href='#' to="/offers">This is a sample scrolling text that has scrolls texts to left.</a> */}
                 
</marquee>

      {/* Register */}

      <Modal
        show={showUp}
        onHide={handleCloseUp}
        backdrop="static"
        keyboard={false}
        className="pt-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleRegisterSubmit} className="row g-1">
            <div className="col-md-12">
              <label htmlFor="validationDefaultRegisterName" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="validationDefaultRegisterName"
                name="name"
                value={inputRegisterData.name}
                onChange={handleRegisterChange}
                autoComplete="off"
                required
              />
            </div>
            <div className="col-md-12">
              <label htmlFor="validationDefaultRegisterEmail" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="validationDefaultRegisterEmail"
                name="EmailId"
                value={inputRegisterData.EmailId}
                onChange={handleRegisterChange}
                autoComplete="off"
                required
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="validationDefaultRegisterNumber" className="form-label">
                Mobile No.
              </label>
              <input
                type="number"
                className="form-control"
                id="validationDefaultRegisterNumber"
                name="MobileNumber"
                value={inputRegisterData.MobileNumber}
                onChange={handleRegisterChange}
                autoComplete="off"
                required
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="validationDefault04" className="form-label">
                User Type
              </label>
              <select
                className="form-select"
                id="validationDefault04"
                name="UserType"
                onChange={handleRegisterChange}
                value={inputRegisterData.UserType}
                autoComplete="off"
                required
              >
                <option defaultValue disabled value="">
                  Choose...
                </option>
                <option>Admin</option>
                <option>Associate</option>
              </select>
            </div>
            <div className="col-md-12">
              <label htmlFor="validationDefaultRegisterUserId" className="form-label">
                User Id
              </label>
              <div className="input-group">
                <span className="input-group-text" id="inputGroupPrepend3">
                  @
                </span>
                <input
                  name="userId"
                  value={inputRegisterData.userId}
                  onChange={handleRegisterChange}
                  autoComplete="off"
                  type="email"
                  className="form-control"
                  id="validationDefaultRegisterUserId"
                  aria-describedby="inputGroupPrepend2"
                  required
                />
              </div>
            </div>
            <div className="col-md-12">
              <label htmlFor="validationDefaultRegisterPassword" className="form-label">
                Password
              </label>
              <input
                type="password"
                value={inputRegisterData.password}
                onChange={handleRegisterChange}
                className="form-control"
                id="validationDefaultRegisterPassword"
                name="password"
                autoComplete="off"
                required
              />
            </div>
            <div className="col-12 text-center pt-2">
              <button className="btn btn-success" type="submit">
                Create User
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* sign in */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="pt-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleLoginSubmit} className="row g-1">
            <div className="col-md-12">
              <label htmlFor="validationDefaultUsername" className="form-label">
                User Id
              </label>
              <div className="input-group">
                <span className="input-group-text" id="inputGroupPrepend2">
                  @
                </span>
                <input
                  name="userId"
                  value={inputLoginData.userId}
                  onChange={handleLoginChange}
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  id="validationDefaultUsername"
                  aria-describedby="inputGroupPrepend2"
                  required
                />
              </div>
            </div>
            <div className="col-md-12">
              <label htmlFor="validationDefault02" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="validationDefault02"
                name="password"
                value={inputLoginData.password}
                onChange={handleLoginChange}
                autoComplete="off"
                required
              />
            </div>
            <div className="col-12 text-center pt-3">
            {
              loginLoading === true ? <button className="btn btn-success" type="submit">
                Loading...
              </button> :
              <button className="btn btn-success" type="submit">
                Submit form
              </button>
            }
              
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;
