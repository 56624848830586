import React from "react";
import "./Counts.css";

const Counts = () => {
  return (
    <>
      <section id="counts" className="counts section-bg">
        <div className="container">
          <div className="row counters d-flex justify-content-between">
            <div className="col-lg-6 col-6 text-center">
              <span
                data-purecounter-start="0"
                data-purecounter-end="64"
                data-purecounter-duration="1"
                className="purecounter"
              >
                100 +
              </span>
              <p>Courses</p>
            </div>

            <div className="col-lg-6 col-6 text-center">
              <span
                data-purecounter-start="0"
                data-purecounter-end="42"
                data-purecounter-duration="1"
                className="purecounter"
              >
                121 +
              </span>
              <p>Events</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Counts;
