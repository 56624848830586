import React from "react";
import "./Footer.css";
import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";
import { useLocation } from "react-router-dom";
// import {GrGooglePlus, GrLinkedinOption} from "react-icons/gr"

const Footer = () => {
  const location = useLocation()
  return (
    <>
      <footer id="footer">
        <div className="footer-top pt-4">
          <div className="container">
            <div className="row text-center">
            {
              location.pathname === "/admin" ? "" :
              <div className="col-md-12 footer-contact">
                <h5>Administrative Office</h5>
                <p>
                Near Bansi Das Middle School,
                <br /> Behind Bajaj Electronic Showroom, <br /> GN Ganj, Laheriasarai
                  <br />
                  Darbhanga-846003
                  <br />
                {/* 2<sup>nd</sup> Floor Moin Complex East of Town Hall,<br /> Near C.M Science College, <br /> Lalbagh, Darbhanga.
                  <br />
                  Bihar, PIN 846004
                  <br /> */}
                  {/* India <br /> */}
                  <strong>Phone:</strong> +91 9608917772, 6203800996, 6201716848
                  <br />
                  <strong>Email:</strong> info@mitms.in
                  <br />
                </p>
              </div>

            }

              {/* <div className="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <Link to="/">Home</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="/about">About</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="/courses">Courses</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="/scholarship">Contact</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="/offers">Offers</Link></li>
            </ul>
          </div> */}

              {/* <div className="col-md-6 footer-links">
                <h5>Our Services</h5>
                <ul style={{ lineHeightStep: "150px" }}>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link to="/">HR Solutions</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link to="/">Education Consultaion</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link to="/">Digital Promotion</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link to="/">Web Page Development</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link to="/">POS And IT Solutions</Link>
                  </li>
                </ul>
              </div> */}

              {/* <div className="col-lg-4 col-md-6 footer-newsletter">
            <h4>Join Our Newsletter</h4>
            <p>Through following links</p>
            <form action="" method="post">
              <input type="email" name="email"/><input type="submit" value="Subscribe"/>
            </form>
          </div> */}
            </div>
          </div>
        </div>

        <div className="container d-md-flex py-4">
          <div className="me-md-auto text-center text-md-start">
            <div className="copyright">
              &copy; Copyright{" "}
              <strong>
                <span>MITMS</span>
              </strong>
              . All Rights Reserved
            </div>
            {/* <div className="credits">
              Designed by <Link to="/">Rahbar</Link>
            </div> */}
          </div>
          <div className="social-links text-center text-md-right pt-3 pt-md-0">
            <a
              href="https://twitter.com/MitmsDbg"
              rel="noreferrer"
              target="_blank"
              className="twitter"
            >
              <FiTwitter />
            </a>
            <a
              href="https://www.facebook.com/mitmsdbg/"
              rel="noreferrer"
              target="_blank"
              className="facebook"
            >
              <FiFacebook />
            </a>
            <a
              href="https://www.instagram.com/mithila.paramedical/"
              rel="noreferrer"
              target="_blank"
              className="instagram"
            >
              <FiInstagram />
            </a>
            {/* <Link to="/" className="google-plus"><GrGooglePlus/></Link>
        <Link to="/" className="linkedin"><GrLinkedinOption/></Link> */}
          </div>
        </div>
      </footer>

      {/* <div id="preloader"></div>
  <Link to="/" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></Link> */}
    </>
  );
};

export default Footer;
