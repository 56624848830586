const Galleryimg = [
    {
    id:1,
    image:"images/slide-7.jpg",
    name:"Event Pic",

},
{
    id:2,
    image:"images/slide-6.jpg",
    name:"Event Pic",

},
{
    id:3,
    image:"images/img-17.jpeg",
    name:"Event Pic",

},
{
    id:7,
    image:"images/slide-1.jpg",
    name:"Event Pic",

},
{
    id:8,
    image:"images/slide-2.jpg",
    name:"Event Pic",

},
{
    id:13,
    image:"images/lab-1.jpg",
    name:"Event Pic",
    disc:"Mithila Institute of Technology & Medical Science(MITMS) Classroom."

},
{
    id:14,
    image:"images/lab-2.jpg",
    name:"Event Pic",
    disc:"Practical by students of Mithila Institute of Technology & Medical Science."

},
{
    id:15,
    image:"images/lab-8.jpg",
    name:"Event Pic",
    disc:"Practical by students of Mithila Institute of Technology & Medical Science."

},
{
    id:16,
    image:"images/lab-41.jpg",
    name:"Event Pic",
    disc:"Students practicing in the MITMS operation theatre."

},
{
    id:17,
    image:"images/lab-42.jpg",
    name:"Event Pic",
    disc:"MITMS students,  practical sessions."

},
{
    id:19,
    image:"images/lab-43.jpg",
    name:"Event Pic",
    disc:"X-Ray Lab, Practical session at Mithila Institute of Technology & Medical Science."

},
{
    id:20,
    image:"images/lab-44.jpg",
    name:"Event Pic",
    disc:"Pathology Lab equipment to process samples."

}
]

export default Galleryimg;