import React, {useState} from 'react'
import axios from 'axios';

const Student = ({showAlert, curUser}) => {
    const [inputData, setInputData] = useState({
      name: "",
      fatherName: "",
      mobile: "",
      email: "",
      district: "",
      qualification: "",
      edu:"",
      course: "",
      fIncome: "",
      textArea: ""
    });
    const handleChange = (e) => {
      const name = e.target.name;
      const value = e.target.value;
      setInputData({ ...inputData, [name]: value });
    };
    const handleSubmit = (e) => {
      const {name, fatherName, mobile, email, district, qualification, edu, course, fIncome, textArea} = inputData
      e.preventDefault();
      axios.post('https://mitms.in/api/Enquiry/PostCustEnquiry', {
        CustomerName:name,
        CustRef:curUser,
        MobileNumber:mobile,
        EmailId:email,
        Course:course,
        Address:"",
        State:"",
        Comments:textArea,
        fatherName:fatherName,
        district:district,
        fIncome:fIncome,
        stream:edu,
        qualification:qualification
      })
      .then(function (response) {
        if(response.status === 200){
          showAlert("Someone will get in touch with you soon.", "success")
        }else{
          showAlert("Something is wrong.", "danger")
        }
      })
      .catch(function (error) {
        showAlert(error, "danger");
      });
      setInputData({
        name: "",
        fatherName:"",
        mobile: "",
        email: "",
        district: "",
        qualification: "",
        edu:"",
        course: "",
        fIncome: "",
        textArea: ""
      });
    };
  return (
    <>
        <div className='container py-4 pb-5'>
        <h2 className='pb-3 text-center'>Registration Form</h2>
        <form onSubmit={handleSubmit} className="row g-1">
            <div className="col-md-6">
              <label htmlFor="validationDefault01" className="form-label">
                Name of the candidate
              </label>
              <input
                type="text"
                className="form-control"
                id="validationDefault01"
                name="name"
                onChange={handleChange}
                value={inputData.name}
                autoComplete="off"
                required
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="validationDefault01" className="form-label">
                Father's Name
              </label>
              <input
                type="text"
                className="form-control"
                id="validationDefault01"
                name="fatherName"
                onChange={handleChange}
                value={inputData.fatherName}
                autoComplete="off"
                required
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="validationDefault02" className="form-label">
                Mobile number
              </label>
              <input
                type="tel"
                className="form-control"
                id="validationDefault02"
                name="mobile"
                onChange={handleChange}
                value={inputData.mobile}
                autoComplete="off"
                required
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="validationDefaultUsername" className="form-label">
                Email id
              </label>
              <div className="input-group">
                <span className="input-group-text" id="inputGroupPrepend2">
                  @
                </span>
                <input
                  name="email"
                  onChange={handleChange}
                  value={inputData.email}
                  autoComplete="off"
                  type="email"
                  className="form-control"
                  id="validationDefaultUsername"
                  aria-describedby="inputGroupPrepend2"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="validationDefaultUsername" className="form-label">
                District
              </label>
              <div className="input-group">
                <input
                  name="district"
                  onChange={handleChange}
                  value={inputData.district}
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  id="validationDefaultUsername"
                  aria-describedby="inputGroupPrepend2"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="validationDefaultUsername" className="form-label">
                Family's annual income
              </label>
              <div className="input-group">
                <input
                  name="fIncome"
                  onChange={handleChange}
                  value={inputData.fIncome}
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  id="validationDefaultUsername"
                  aria-describedby="inputGroupPrepend2"
                  required
                />
              </div>
            </div>
            <div className="col-md-4">
              <label htmlFor="validationDefaultUsername" className="form-label">
                Qualification
              </label>
              <div className="input-group">
                <input
                  name="qualification"
                  onChange={handleChange}
                  value={inputData.qualification}
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  id="validationDefaultUsername"
                  aria-describedby="inputGroupPrepend2"
                  required
                />
              </div>
            </div>

            <div className="col-md-4">
              <label htmlFor="validationDefault04" className="form-label">
                10+2 with
              </label>
              <select
                className="form-select"
                id="validationDefault04"
                name="edu"
                onChange={handleChange}
                value={inputData.edu}
                autoComplete="off"
                required
              >
                <option defaultValue disabled value="">
                  Choose...
                </option>
                <option>Maths</option>
                <option>Bio</option>
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="validationDefault04" className="form-label">
                Interested in
              </label>
              <select
                className="form-select"
                id="validationDefault04"
                name="course"
                onChange={handleChange}
                value={inputData.course}
                autoComplete="off"
                required
              >
                <option defaultValue disabled value="">
                  Choose...
                </option>
                <option>Medical courses</option>
                <option>Non-medical courses</option>
              </select>
            </div>{" "}
            <div className="col-12">
              <div className="mb-3">
                <label htmlFor="validationTextarea" className="form-label">
                  Leave comments
                </label>
                <textarea
                  className="form-control"
                  name="textArea"
                  onChange={handleChange}
                  value={inputData.textArea}
                  autoComplete="off"
                  id="validationTextarea"
                  placeholder="Leave comments"
                ></textarea>
                <div className="invalid-feedback">
                  Please enter a message in the textarea.
                </div>
              </div>
            </div>
            <div className="col-12 text-center pt-2">
              <button className="btn btn-success px-5" type="submit">
                Register
              </button>
            </div>
          </form>

        </div>
    </>
  )
}

export default Student