import { Link } from "react-router-dom";
import "../login/Login.css";

const Login = () => {
  return (
    <>
      <div
        className="container-fluid bg-container-color d-flex align-items-center justify-content-center"
        style={{ height: "90vh" }}
      >
        <div className="row border bg-login p-sm-5 py-4 shadow">
          <h1
            className="text-center mb-2 text-light"
            style={{ letterSpacing: "5px", textDecoration: "underline" }}
          >
            Login
          </h1>
          <div className="col-12">
            <div className="text-end my-3">
              <Link
                to="/signup"
                className="text-secondary"
                style={{ letterSpacing: "2px" }}
              >
                <span style={{color:"wheat"}}>Create an Account ?</span>
              </Link>
            </div>
            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
              />
              <label htmlFor="floatingInput">Email address</label>
            </div>
            <div className="form-floating">
              <input
                type="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
              />
              <label htmlFor="floatingPassword">Password</label>
            </div>
            <div className="d-flex justify-content-between mt-4">
              <button className="btn btn-color me-2 shadow-none">Login</button>
              <button className="btn btn-color ms-2 shadow-none">
                Forget Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
