import React from "react";

const AdminScholarship = ({curElem, i, setCustid}) => {
  const handleAssociateClick = (id) => {
    setCustid(id);
  }
  return (
    <>
      <tbody className="fs-5 align-middle">
        <tr style={{fontSize:"0.8rem"}}>
          <th scope="row">{i+1}</th>
          <td onClick={() => handleAssociateClick(curElem.CustRefId)} style={{cursor:"pointer"}}>{curElem.CustRef}</td>
          <td>{curElem.CustomerName}</td>
          <td>{curElem.fatherName}</td>
          <td>{curElem.EmailId}</td>
          <td>{curElem.district}</td>
          <td>{curElem.MobileNumber}</td>
          <td>{curElem.Course}</td>
          <td>{curElem.qualification}</td>
          <td>{curElem.stream}</td>
          <td>{curElem.Address}</td>
          <td>{curElem.State}</td>
          <td>{curElem.Date} | {curElem.Time}</td>
        </tr>
      </tbody>
    </>
  );
};

export default AdminScholarship;
