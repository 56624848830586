import React from 'react';
import Counts from '../counts/Counts';
import Whyus from '../whyus/Whyus';
// import Appfeature from '../appfeature/Appfeature';
import Popularcourse from '../popularcourse/Popularcourse';
import Trainer from '../trainer/Trainer';
import Home from '../home/Home';
import AboutHome from '../about/AboutHome';
import AutoPlay from '../AutoPlay';

const Mainpage = (props) => {
    const {handleShow} = props
    return (
        <>
            <Home handleShow={handleShow} />
            <div id="main">
                <AboutHome/>
                <Counts/>
                <Whyus/>
                {/* <Appfeature/> */}
                <AutoPlay/>
                <Popularcourse handleShow={handleShow} />
                <Trainer/>
            </div>
   
        </>
    )
}

export default Mainpage
