import React from "react";

const Alert = ({ alert }) => {
    const captlize = (word) => {
        const lower = word.toLowerCase()
        return lower.charAt(0).toUpperCase() + lower.slice(1)
    }
  return (
    <>
    <div>
      {alert && (
        <div
          className={`alert alert-${alert.type} alert-dismissible fade show fixed-bottom mx-auto text-center`}
          style={{maxWidth:"348px", zIndex:"9999", marginBottom:"60px"}}
          role="alert"
        >
          <strong>{captlize(alert.msg)}</strong>
        </div>
      )}
      </div>
    </>
  );
};

export default Alert;
