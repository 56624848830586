import React from "react";
import { FaRegHandPointRight } from "react-icons/fa";
import { BsDot } from "react-icons/bs";
import "./Whyus.css";
import {FcCheckmark} from "react-icons/fc"

const Whyus = () => {
  return (
    <>
      <div className="container my-4">
        <div className="row">
          <h1 className="fw-bold my-3 px-2" style={{ color: "#36447B" }}>
            Why Choose MITMS?
          </h1>
          <p className="mb-4 px-2" style={{ color:"black", lineHeight: "1.7" }}>
            {/* The job, an ultimate dream of any aspirant which MITMS helps to
            achieve the candidate admitted right from the first day. */}
            {/* “High deliverables of international
            measures to meet market standards our students’ part us from the
            others. And all this is possible because the institution is run by a
            group of experienced professionals and experts of diverse fields
            likeDoctors, Engineers, HSE, IT, Company Secretary &Management
            Experts, who have come together to build a client-centric
            institution. With just an aim to provide quality education at an
            affordable cost.
            <br />
            The institution with a mission to
            achieve excellence at an affordable cost to make your dream come
            true, Take pride in its ability to provide quality service,
            education, research with the sole aim to ameliorate standards in the
            healthcare, management, and other technical sectors.” */}
            <FcCheckmark/> &nbsp; High deliverables of international measures.<br/>
            <FcCheckmark/> &nbsp; Preparation of international market standards.<br/>
            <FcCheckmark/> &nbsp; Managed by a group of internationally experienced professionals.<br/>
            <FcCheckmark/> &nbsp; Quality service and education to ameliorate sectors in healthcare, management, and other technical sectors.

          </p>
<br/><br/>
          <div className="col-md-6 px-2">
            <h4 className="fw-bold">
              1<BsDot /> Management Team
            </h4>
            <p>
            <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />
Skilled and experienced.           </p>
            {/* <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              Leader in Healthcare Education & Safety audits.
            </p> */}
            {/* <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              The Paramedical Institute managed by Doctors in Darbhanga.
            </p>
            <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              The safety training delivered by experienced and highly qualified
              trainers.
            </p>
            <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              Under Leadership of Dr.Ahmad Naseem Arzoo, managed by Mithila
              Institute of Technology & Medical Science. And owned by group of
              experienced professionals.
            </p> */}
            <hr className="" />
            <h4 className="fw-bold">
              2<BsDot /> Facilities
            </h4>
            <p>
            <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />
            State of art and one of its own kind.
            </p>
            {/* <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              MITMS offers UGC and State Government recognised job oriented
              courses.
            </p> */}
            {/* <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              Fully Air-conditioned and Wi-fi enabled Campus.
            </p>
            <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              State of the art Library with vast collection of books and
              journals.
            </p>
            <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              State of the art in house practical labs with latest technology
              medical equipments.
            </p>
            <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              Specialized classes within candidates personality grooming session
              of Soft skills, English and basic computer classes. For
              Non-Science background candidates as well.
            </p> */}
            <hr className="" />
            <h4 className="fw-bold">
              3<BsDot /> Faculties (Trainers){" "}
            </h4>
            <p>
            <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />
            Experienced with academic excellence.
            </p>
            {/* <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              Experienced faculty under the leadership of Dr. Arzoo.
            </p> */}
            {/* <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              Approved Trainers from NSDC, and gold medal awarded. Mithila
              Institute of Technology & Medical Science Certified trainers to
              deliver quality Education.
            </p>
            <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              Doubt clear sessions with students on need basis.
            </p> */}
          </div>
          <div className="col-md-6 px-2">
            {/* <h4 className="fw-bold">
              4<BsDot /> Media & CSR
            </h4>
            <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              Media partner, associated with local and nationalized media houses
              so that any updated and upgrades for candidates career never skips
              from your eyes.
            </p>
            <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              Preferred choice amongst numerous organizations for their CSR
              initiatives.
            </p>
            <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              Mithila Institute of Technology & Medical Science contributes
              directly to 7 of the Sustainable development goal (SDG) out of 17
              adopted by United Nations.
            </p>*/}
            <h4 className="fw-bold">
              4<BsDot /> Trainings & Internship{" "}
            </h4>
            <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />16 hospitals as training partners in the town.
            </p>
            {/* <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              Skill based live training sessions at Al-Helal Hospital
            </p> */}
            {/* <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              Dedicated Training Cell.
            </p>
            <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              In house training on latest medical equipment by highly qualified
              trainers.
            </p> */}
            <hr className="" />
            <h4 className="fw-bold">
              5<BsDot /> Placement
            </h4>
            {/* <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              The job, the ultimate dream of any
            aspirant which MITMS helps to achieve the candidate admitted right
            from the first day.
            </p> */}
            <p>
              <FaRegHandPointRight
                className="me-3"
                style={{ color: "#36447B" }}
              />{" "}
              We groom our students for interview skills and other confidence
              building measures to help them succeed in the placement process.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Whyus;
