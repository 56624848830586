import React, { useState } from "react";
import Navbar from "./features/navbar/Navbar";
import "./App.css";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Mainpage from "./features/Mainpage/Mainpage";
import About from "./features/about/About";
import Courses from "./features/courses/Courses";
import Footer from "./features/footer/Footer";
import Scholarship from "./features/scholarship/Scholarship";
import Offers from "./features/offers/Offers";
import Contact from "./features/contact/Contact";
import Admission from "./features/admission/Admission";
import Admin from "./features/admin/Admin";
import Login from "./features/login/Login";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import SignUp from "./features/signup/SignUp";
import Gallery from "./features/gallery/Gallery";
import ScrollTop from "./features/ScrollTop";
import axios from "axios";
import Alert from "./features/Alert";
import BackToTop from "./features/BackToTop";
import Student from "./features/admin/Student";

const App = () => {
  const [alert, setAlert] = useState(null);
  const [login, setLogin] = useState(false);
  const [show, setShow] = useState(false);
  const [curUser, setCurUser] = useState(null);
  const [custid, setCustid] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 1600);
  };
  useEffect(() => {
    setCurUser("Self")
  }, [])
  
  useEffect(() => {
    setTimeout(() => {
      handleShow();
    }, 5000);
  }, []);
  const [inputData, setInputData] = useState({
    name: "",
    mobile: "",
    email: "",
    course: "",
    state: "",
    textArea: "",
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputData({ ...inputData, [name]: value });
  };
  const handleSubmit = (e) => {
    const { name, mobile, email, course, state, textArea } = inputData;
    e.preventDefault();
    axios
      .post("https://mitms.in/api/Enquiry/PostCustEnquiry", {
        CustomerName: name,
        CustRef: curUser,
        MobileNumber: mobile,
        EmailId: email,
        Course: course,
        Address: "",
        State: state,
        Comments: textArea,
        fatherName: "",
        district: "",
        fIncome: "",
        stream: "",
        qualification: "",
      })
      .then(function (response) {
        if (response.status === 200) {
          showAlert("Someone will get in touch with you soon. 👌", "success");
        } else {
          showAlert("Someone is wrong.", "danger");
        }
      })
      .catch(function (error) {
        showAlert(error.message);
      });
    setInputData({
      name: "",
      mobile: "",
      email: "",
      course: "",
      address: "",
      state: "",
      textArea: "",
    });
    handleClose();
  };
  

  return (
    <>
    
      <Router>
        <ScrollTop />
        <Alert alert={alert} />
        <Navbar
          curUser={curUser}
          setCurUser={setCurUser}
          showAlert={showAlert}
          login={login}
          setLogin={setLogin}
        />
        <Switch>
          <Route path="/" exact>
            <Mainpage handleShow={handleShow} curUser={curUser} />
          </Route>
          <Route path="/about" exact>
            <About />
          </Route>
          <Route path="/courses" exact>
            <Courses handleShow={handleShow} />
          </Route>
          <Route path="/scholarship" exact>
            <Scholarship />
          </Route>
          <Route path="/admission">
            <Admission />
          </Route>
          <Route path="/offers" exact>
            <Offers
              inputData={inputData}
              setInputData={setInputData}
              handleShow={handleShow}
              showAlert={showAlert}
              curUser={curUser}
            />
          </Route>
          <Route path="/gallery" exact>
            <Gallery />
          </Route>
          <Route path="/contact" exact>
            <Contact showAlert={showAlert} curUser={curUser} />
          </Route>
          {login === true && (
            <Route path="/admin">
              <Admin showAlert={showAlert} custid={custid} setCustid={setCustid} />
            </Route>
          )}
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/signup">
            <SignUp />
          </Route>
          {login === true && (
            <Route path="/student">
              <Student showAlert={showAlert} curUser={curUser} />
            </Route>
          )}
          <Redirect to="/" />
        </Switch>
        <BackToTop />
        <Footer />
      </Router>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="d-flex align-items-center"
      >
        <Modal.Header closeButton>
          <Modal.Title>Enquiry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit} className="row g-1">
            <div className="col-md-12">
              <label htmlFor="validationDefault01" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="validationDefault01"
                name="name"
                onChange={handleChange}
                value={inputData.name}
                autoComplete="off"
                required
              />
            </div>
            <div className="col-md-12">
              <label htmlFor="validationDefaultUsername" className="form-label">
                Email id
              </label>
              <div className="input-group">
                <span className="input-group-text" id="inputGroupPrepend2">
                  @
                </span>
                <input
                  name="email"
                  onChange={handleChange}
                  value={inputData.email}
                  autoComplete="off"
                  type="email"
                  className="form-control"
                  id="validationDefaultUsername"
                  aria-describedby="inputGroupPrepend2"
                />
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="validationDefault02" className="form-label">
                Mobile number
              </label>
              <input
                type="tel"
                className="form-control"
                id="validationDefault02"
                name="mobile"
                onChange={handleChange}
                value={inputData.mobile}
                autoComplete="off"
                required
              />
            </div>
            <div className="col-md-6 ps-md-5">
              <label htmlFor="validationDefault04" className="form-label">
                Select course
              </label>
              <select
                className="form-select"
                id="validationDefault04"
                name="course"
                onChange={handleChange}
                value={inputData.course}
                autoComplete="off"
                required
              >
                <option defaultValue disabled value="">
                  Choose...
                </option>
                <option>Medical Courses</option>
                <option>Non-medical Courses</option>
              </select>
            </div>{" "}
            <div className="col-12">
              <div className="mb-3">
                <label htmlFor="validationTextarea" className="form-label">
                  Comments
                </label>
                <textarea
                  className="form-control"
                  name="textArea"
                  onChange={handleChange}
                  value={inputData.textArea}
                  autoComplete="off"
                  id="validationTextarea"
                  placeholder="Remarks"
                ></textarea>
                <div className="invalid-feedback">
                  Please enter a message in the textarea.
                </div>
              </div>
            </div>
            <div className="col-12 text-center">
              <button className="btn btn-success" type="submit">
                Submit form
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default App;
