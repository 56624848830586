import React from "react";

const CourseItems = (props) => {
  const { CourseData, handleShow } = props;
  return (
    <>
      {CourseData.map((curElem) => {
        return (
          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch my-2"
            key={curElem.Id}
          >
            <div className="course-item w-100">
              <img
                src={curElem.Image}
                className="img-fluid w-100"
                style={{ height: "30vh" }}
                alt="course"
              />
              <div className="course-content">
                <div className="d-flex justify-content-between align-items-center mb-3">
                {/* {
                 (curElem.Abbreviation === null) ? "" : <h4 style={{background:"white", color:"#36447B", fontWeight:"bold"}}>{curElem.Abbreviation}</h4>
                } */}
                  {/* <p className="price">₹{curElem.TotalFee}</p> */}
                </div>
                <h3>{curElem.CourseName}</h3>
                {/* <p>{curElem.Desc}</p> */}
                <div className="text-end">
                  <button
                    onClick={handleShow}
                    className="btn btn-sm btn-success"
                  >
                    Know More
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CourseItems;
