import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <>
      <div
        id="carouselExampleCaptions"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators d-none">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner" id="hero">
          <div id="heroImg" className="carousel-item active">
            <div className="carousel-caption px-0 mx-0">
              <h1>
                Education breeds confidence. <br /> Confidence breeds hope.{" "}
                <br /> Hope breeds peace.
              </h1>
            </div>
          </div>
          <div id="heroOne" className="carousel-item">
            <div className="carousel-caption">
              <h1>
                Education is the key to unlock the golden door of freedom.
              </h1>
            </div>
          </div>
          <div id="heroTwo" className="carousel-item">
            <div className="carousel-caption">
              <h1>
                Education is not only a ladder of opportunity, but it is also an
                investment in our future.
              </h1>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon d-none"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon d-none"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
};

export default Home;

// import React from "react";
// import "./Home.css";

// const Home = () => {
//   return (
//     <>
//       <section
//         id="hero"
//         className="d-flex justify-content-center align-items-center"
//       >
//         <div className="container position-relative">
//           <h1>
//             Educations breeds confidence.
//             <br />
//             Confidence breeds hope.
//             <br />
//             Hope breeds peace.
//           </h1>
//           {/* <span onClick={handleShow} className="btn btn-get-started">
//             About MITMS
//           </span> */}
//         </div>
//       </section>
//     </>
//   );
// };

// export default Home;
