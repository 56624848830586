const autoPlayApi = [
    {
    id:1,
    image:"images/slide-7.jpg",
    name:"Event Pic",
    disc:"Mr. Anand Kumar’s, Founder Super-30, Visit to Mithila Institute of Technology& Medical Science."

},
{
    id:2,
    image:"images/img-17.jpeg",
    name:"Event Pic",
    disc:"MLA, Darbhanga town, Honorable Sri Sanjay Saraogi, at Inauguration of 2nd premise of Mithila Institute of Technology & Medical Science."

},
{
    id:3,
    image:"images/lab-1.jpg",
    name:"Event Pic",
    disc:"Mithila Institute of Technology & Medical Science(MITMS) Classroom."

},
{
    id:4,
    image:"images/lab-2.jpg",
    name:"Event Pic",
    disc:"Practical by students of Mithila Institute of Technology & Medical Science."

},
{
    id:5,
    image:"images/lab-8.jpg",
    name:"Event Pic",
    disc:"Practical by students of Mithila Institute of Technology & Medical Science."

},
{
    id:6,
    image:"images/lab-41.jpg",
    name:"Event Pic",
    disc:"Students practicing in the MITMS operation theatre."

},
{
    id:7,
    image:"images/lab-42.jpg",
    name:"Event Pic",
    disc:"MITMS students,  practical sessions."

},
{
    id:8,
    image:"images/lab-46.jpg",
    name:"Event Pic",
    disc:"Pathology lab training session of students of MITMS."

},
{
    id:9,
    image:"images/lab-43.jpg",
    name:"Event Pic",
    disc:"X-Ray Lab, Practical session at Mithila Institute of Technology & Medical Science."

},
{
    id:10,
    image:"images/lab-44.jpg",
    name:"Event Pic",
    disc:"Pathology Lab equipment to process samples."

}
]

export default autoPlayApi;