import React, {useState} from "react";
import "./Offers.css";
import { Modal } from "react-bootstrap";
import axios from "axios";

const Offers = ({showAlert, curUser}) => {
  const [scName, setScName] = useState("")
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (val) => {
    setScName(val)
    setShow(true)
  };
  const [inputData, setInputData] = useState({
    name: "",
    fatherName: "",
    mobile: "",
    email: "",
    district: "",
    qualification: "",
    edu:"",
    course: "",
    fIncome: "",
    textArea: ""
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputData({ ...inputData, [name]: value });
  };
  const handleSubmit = (e) => {
    const {name, fatherName, mobile, email, district, qualification, edu, course, fIncome, textArea} = inputData
    e.preventDefault();
    axios.post('https://mitms.in/api/Enquiry/PostCustEnquiry', {
      CustomerName:name,
      CustRef:curUser,
      MobileNumber:mobile,
      EmailId:email,
      Course:course,
      Address:"",
      State:"",
      Comments:textArea,
      fatherName:fatherName,
      district:district,
      fIncome:fIncome,
      stream:edu,
      qualification:qualification
    })
    .then(function (response) {
      if(response.status === 200){
        showAlert("Someone will get in touch with you soon.", "success")
        handleClose();
      }else{
        showAlert("Something is wrong.", "danger")
      }
    })
    .catch(function (error) {
      showAlert(error, "danger");
    });
    setInputData({
      name: "",
      fatherName:"",
      mobile: "",
      email: "",
      district: "",
      qualification: "",
      edu:"",
      course: "",
      fIncome: "",
      textArea: ""
    });
  };

  return (
    <>
      <div className="breadcrumbs">
        <div className="container">
          <h2>Offers</h2>
          <p>
          The cure for boredom is curiosty
          </p>
        </div>
      </div>

      <div className="container py-4" style={{ minHeight: "85vh" }}>
        <div className="row">
          <div className="col-md-12">
            <ul style={{ listStyleType: "none" }}>
              <li className="my">
                {/* <h2>Scholarship</h2> */}
                <ul>
                  <li>
                    <span
                      style={{ color: "#002ca5", cursor:"pointer" }}
                      onClick={() => handleShow("National Scholarship")}
                    >
                      National Scholarship
                    </span>
                  </li>
                  <li>
                    <span
                      style={{ color: "#002ca5", cursor:"pointer" }}
                      onClick={() => handleShow("Association of Muslim Professionals (AMP) Scholarship")}
                    >
                      Association of Muslim Professionals (AMP) Scholarship
                    </span>
                  </li>

                  <li>
                    <a
                      // href="https://docs.google.com/forms/d/e/1FAIpQLSeHHztiYPxP8q-5EMyC2__WvVmj8cEcIiMjeoIZ-uNEOaF_eA/viewform"
                      // target="_blank"
                      // rel="noreferrer"
                      style={{ color: "#002ca5", cursor:"pointer" }}
                       onClick={() => handleShow("Aisha Scholarship")}
                    >
                      Aisha Scholarship (Scholarship up to 75% on limited seats)
                    </a>
                  </li>
                  <li>
                    <span
                      style={{ color: "#002ca5", cursor:"pointer" }}
                      onClick={() => handleShow("Bihar Anjuman Scholarship")}
                    >
                      Bihar Anjuman Scholarship
                    </span>
                  </li>
                </ul>
              </li>
              <li className="fs-6 my-4">
                Note:- These will be subjected to the availability of the
                scholarships and will be activated as and when required.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{scName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit} className="row g-1">
            <div className="col-md-6">
              <label htmlFor="validationDefault01" className="form-label">
                Name of the candidate
              </label>
              <input
                type="text"
                className="form-control"
                id="validationDefault01"
                name="name"
                onChange={handleChange}
                value={inputData.name}
                autoComplete="off"
                required
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="validationDefault01" className="form-label">
                Father's Name
              </label>
              <input
                type="text"
                className="form-control"
                id="validationDefault01"
                name="fatherName"
                onChange={handleChange}
                value={inputData.fatherName}
                autoComplete="off"
                required
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="validationDefault02" className="form-label">
                Mobile number
              </label>
              <input
                type="tel"
                className="form-control"
                id="validationDefault02"
                name="mobile"
                onChange={handleChange}
                value={inputData.mobile}
                autoComplete="off"
                required
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="validationDefaultUsername" className="form-label">
                Email id
              </label>
              <div className="input-group">
                <span className="input-group-text" id="inputGroupPrepend2">
                  @
                </span>
                <input
                  name="email"
                  onChange={handleChange}
                  value={inputData.email}
                  autoComplete="off"
                  type="email"
                  className="form-control"
                  id="validationDefaultUsername"
                  aria-describedby="inputGroupPrepend2"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="validationDefaultUsername" className="form-label">
                District
              </label>
              <div className="input-group">
                <input
                  name="district"
                  onChange={handleChange}
                  value={inputData.district}
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  id="validationDefaultUsername"
                  aria-describedby="inputGroupPrepend2"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="validationDefaultUsername" className="form-label">
                Family's annual income
              </label>
              <div className="input-group">
                <input
                  name="fIncome"
                  onChange={handleChange}
                  value={inputData.fIncome}
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  id="validationDefaultUsername"
                  aria-describedby="inputGroupPrepend2"
                  required
                />
              </div>
            </div>
            <div className="col-md-4">
              <label htmlFor="validationDefaultUsername" className="form-label">
                Qualification
              </label>
              <div className="input-group">
                <input
                  name="qualification"
                  onChange={handleChange}
                  value={inputData.qualification}
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  id="validationDefaultUsername"
                  aria-describedby="inputGroupPrepend2"
                  required
                />
              </div>
            </div>

            <div className="col-md-4">
              <label htmlFor="validationDefault04" className="form-label">
                10+2 with
              </label>
              <select
                className="form-select"
                id="validationDefault04"
                name="edu"
                onChange={handleChange}
                value={inputData.edu}
                autoComplete="off"
                required
              >
                <option defaultValue disabled value="">
                  Choose...
                </option>
                <option>Maths</option>
                <option>Bio</option>
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="validationDefault04" className="form-label">
                Interested in
              </label>
              <select
                className="form-select"
                id="validationDefault04"
                name="course"
                onChange={handleChange}
                value={inputData.course}
                autoComplete="off"
                required
              >
                <option defaultValue disabled value="">
                  Choose...
                </option>
                <option>Medical courses</option>
                <option>Non-medical courses</option>
              </select>
            </div>{" "}
            <div className="col-12">
              <div className="mb-3">
                <label htmlFor="validationTextarea" className="form-label">
                  Leave comments
                </label>
                <textarea
                  className="form-control"
                  name="textArea"
                  onChange={handleChange}
                  value={inputData.textArea}
                  autoComplete="off"
                  id="validationTextarea"
                  placeholder="Leave comments"
                ></textarea>
                <div className="invalid-feedback">
                  Please enter a message in the textarea.
                </div>
              </div>
            </div>
            <div className="col-12 text-center">
              <button className="btn btn-success px-5" type="submit">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Offers;
