import React from "react";
import "./Courses.css";
import courseApi from "../../courseApi";
import { useState, useEffect } from "react";
import CourseItems from "./CourseItems";

const uniqueList = [
  "All",
  ...new Set(
    courseApi.map((curElem) => {
      return curElem.CourseType;
    })
  ),
];

const Courses = (props) => {
  const { handleShow } = props;
  const [courseType, setCourseType] = useState([]);
  const [CourseData, setCourseData] = useState([]);
  const [btnValue, setBtnValue] = useState("");
  useEffect(() => {
    setCourseData(courseApi);
    setCourseType(uniqueList);
  }, []);
  const filterItem = (cat) => {
    if (cat === "All") {
      setCourseData(courseApi);
      return;
    }
    const updatedList = courseApi.filter((curElem) => {
      return curElem.CourseType === cat;
    });
    setCourseData(updatedList);
  };
  return (
    <>
      <div className="breadcrumbs">
        <div className="container">
          <h2>Courses</h2>
          <p>
          The cure for boredom is curiosty
          </p>
        </div>
      </div>

      <section id="popular-courses" className="courses py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              {courseType.map((curElem) => {
                return (
                  <button
                    onClick={(e) => {
                      filterItem(curElem);
                      setBtnValue(e.target.value);
                    }}
                    value={curElem}
                    className={`btn shadow-none my-1 my-md-3 mx-1 ${
                      curElem === btnValue
                        ? "btn-success"
                        : "btn-outline-success"
                    }`}
                    key={curElem}
                  >
                    {curElem}
                  </button>
                );
              })}
            </div>
          </div>
          <div className="row" data-aos="zoom-in" data-aos-delay="100">
            <CourseItems CourseData={CourseData} handleShow={handleShow} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Courses;
