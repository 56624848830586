const courseApi = [
  {
    Id: 1,
    CourseName: "Diploma in Medical Laboratory Technology",
    CourseType: "Diploma",
    Image: "/images/Diploma in Medical Laboratory Technology.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: "D.M.L.T",
    Eligibility: "10+2 (PCB)",
    CourseMode: "Yearly",
    Duration: "2 YEARS",
    TotalFee: "90000",
  },
  {
    Id: 2,
    CourseName: "Diploma in Operation Theatre Technology",
    CourseType: "Diploma",
    Image: "/images/Diploma in Operation Theatre Technology.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: "D.O.T.T",
    Eligibility: "10+2 (PCB)",
    CourseMode: "Yearly",
    Duration: "2 YEARS",
    TotalFee: "90000",
  },
  {
    Id: 3,
    CourseName: "Diploma in Dialysis Technology",
    CourseType: "Diploma",
    Image: "/images/Diploma in Dialysis Technology.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: "D.D.T",
    Eligibility: "10+2 (PCB)",
    CourseMode: "Yearly",
    Duration: "2 YEARS",
    TotalFee: "90000",
  },
  {
    Id: 4,
    CourseName: "Diploma in Radiology & Imaging Technology",
    CourseType: "Diploma",
    Image: "/images/Diploma in Radiology & Imaging Technology.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: "DRIT",
    Eligibility: "10+2 (PCB)",
    CourseMode: "Yearly",
    Duration: "2 YEARS",
    TotalFee: "90000",
  },
  {
    Id: 5,
    CourseName: "Diploma in Pharmacy",
    CourseType: "Diploma",
    Image: "/images/Diploma in Pharmacy.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: "D.Pharma",
    Eligibility: "10+2 (PCB)",
    CourseMode: "Yearly",
    Duration: "2 YEARS",
    TotalFee: "250000",
  },
  {
    Id: 6,
    CourseName: "Diploma In Journalism & Mass communication",
    CourseType: "Diploma",
    Image: "/images/Diploma In Journalism & Mass communication.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: "DJMC",
    Eligibility: "10+2",
    CourseMode: "Yearly",
    Duration: "1 Year",
    TotalFee: "35000",
  },
  {
    Id: 7,
    CourseName: "Bachelor of science in Medical Lab Technology",
    CourseType: "Bachelor",
    Image: "/images/Bachelor of science in Medical Lab Technology.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: "B.Sc MLT",
    Eligibility: "10+2",
    CourseMode: "Yearly",
    Duration: "3 YEARS ",
    TotalFee: "135000",
  },
  {
    Id: 8,
    CourseName: "Bachelor of Science in Radiology & Imaging Technology",
    CourseType: "Bachelor",
    Image: "/images/Bachelor of Science in Radiology & Imaging Technology.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: "B.R.I.T.",
    Eligibility: "10+2",
    CourseMode: "Yearly",
    Duration: "3 YEARS ",
    TotalFee: "135000",
  },
  {
    Id: 9,
    CourseName: "Bachelor of Science in Operation Theatre Technology",
    CourseType: "Bachelor",
    Image: "/images/Bachelor of Science in Operation Theatre Technology.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: "B.Sc in Operation Theatre Tech",
    Eligibility: "10+2",
    CourseMode: "Yearly",
    Duration: "3 YEARS",
    TotalFee: "135000",
  },
  {
    Id: 10,
    CourseName: "Bachelor in Dialysis Technology",
    CourseType: "Bachelor",
    Image: "/images/Bachelor in Dialysis Technology.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: "BDT",
    Eligibility: "10+2(with Science)",
    CourseMode: "Yearly",
    Duration: "3 YEARS",
    TotalFee: "135000",
  },
  {
    Id: 11,
    CourseName: "Bachelor of Business Management",
    CourseType: "Bachelor",
    Image: "/images/Bachelor of Business Management.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: "B.B.M",
    Eligibility: "10+2",
    CourseMode: "Semester",
    Duration: "6 Semester",
    TotalFee: "108000",
  },
  {
    Id: 12,
    CourseName: "Bachelor in Journalism & Mass Communication",
    CourseType: "Bachelor",
    Image: "/images/Bachelor in Journalism & Mass Communication.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: "BJMC",
    Eligibility: "10+2",
    CourseMode: "Semester",
    Duration: "6 Semester",
    TotalFee: "135000",
  },
  {
    Id: 13,
    CourseName: "M.B.A. in Management",
    CourseType: "Masters",
    Image: "/images/M.B.A. in Management.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: "M.B.A. in Management",
    Eligibility: "Graduation",
    CourseMode: "Semester",
    Duration: "4 Semester",
    TotalFee: "120000",
  },
  {
    Id: 14,
    CourseName: "PG Diploma in Industrial Safety",
    CourseType: "HSE",
    Image: "/images/PG Diploma in Industrial Safety.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: null,
    Eligibility: "",
    CourseMode: "",
    Duration: "2 Years",
    TotalFee: "55000",
  },
  {
    Id: 15,
    CourseName: "Advance Diploma in Industrial Safety",
    CourseType: "HSE",
    Image: "/images/Advance Diploma in Industrial Safety.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: null,
    Eligibility: "",
    CourseMode: "",
    Duration: "2 Years",
    TotalFee: "55000",
  },
  {
    Id: 16,
    CourseName: "Diploma in Industrial Safety",
    CourseType: "HSE",
    Image: "/images/Diploma in Industrial Safety.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: null,
    Eligibility: "",
    CourseMode: "",
    Duration: "1 Years",
    TotalFee: "45000",
  },
  {
    Id: 17,
    CourseName: "Certificate in Industrial Safety",
    CourseType: "HSE",
    Image: "/images/Certificate in Industrial Safety.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: null,
    Eligibility: "",
    CourseMode: "",
    Duration: "6 Months",
    TotalFee: "35000",
  },
  {
    Id: 18,
    CourseName: "Diploma In Operation Theatre Technology",
    CourseType: "Bihar Government approved courses",
    Image: "/images/Diploma In Operation Theatre Technology bihar1.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: "DOT",
    Eligibility: "10+2 (PCB)",
    CourseMode: "Yearly",
    Duration: "2 Years",
    TotalFee: "92000",
  },
  {
    Id: 19,
    CourseName: "Diploma In Operation Theatre Technology",
    CourseType: "Bihar Government approved courses",
    Image: "/images/Diploma In Operation Theatre Technology bihar2.png",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: "DMLT",
    Eligibility: "10+2 (PCB)",
    CourseMode: "Yearly",
    Duration: "2 Years",
    TotalFee: "92000",
  },
  {
    Id: 20,
    CourseName: "Certificate In Medical Dresser ",
    CourseType: "Bihar Government approved courses",
    Image: "/images/Certificate In Medical dresser.jpg",
    Desc: "Education is the process of facilitating learning, or the acquisition of knowledge, skills, values, morals, beliefs, habits, and personal development. Educational methods include teaching, training, storytelling, discussion and directed research.",
    Abbreviation: "MB",
    Eligibility: "10+2 (PCB)",
    CourseMode: "Yearly",
    Duration: "1 Years",
    TotalFee: "70000",
  },
];

export default courseApi;
