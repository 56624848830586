import React from 'react';
import "./Trainer.css"
// import trainerone from "../images/trainer-1.jpg"
// import trainertwo from "../images/trainer-2.jpg"
// import trainerthree from "../images/trainer-3.jpg"
// import { Link } from 'react-router-dom';


const Trainer = () => {
    return (
        <>

    {/* <section id="trainers" className="trainers">
      <div className="container" data-aos="fade-up">

        <div className="row" data-aos="zoom-in" data-aos-delay="100">
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="member">
              <img src={trainerone} className="img-fluid" alt=""/>
              <div className="member-content">
                <h4>Walter White</h4>
                <span>Web Development</span>
                <p>
                  Magni qui quod omnis unde et eos fuga et exercitationem. Odio veritatis perspiciatis quaerat qui aut aut aut
                </p>
                <div className="social">
                  <Link to="/"><i className="bi bi-twitter"></i></Link>
                  <Link to="/"><i className="bi bi-facebook"></i></Link>
                  <Link to="/"><i className="bi bi-instagram"></i></Link>
                  <Link to="/"><i className="bi bi-linkedin"></i></Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="member">
              <img src={trainertwo} className="img-fluid" alt=""/>
              <div className="member-content">
                <h4>Sarah Jhinson</h4>
                <span>Marketing</span>
                <p>
                  Repellat fugiat adipisci nemo illum nesciunt voluptas repellendus. In architecto rerum rerum temporibus
                </p>
                <div className="social">
                  <Link to="/"><i className="bi bi-twitter"></i></Link>
                  <Link to="/"><i className="bi bi-facebook"></i></Link>
                  <Link to="/"><i className="bi bi-instagram"></i></Link>
                  <Link to="/"><i className="bi bi-linkedin"></i></Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="member">
              <img src={trainerthree} className="img-fluid" alt=""/>
              <div className="member-content">
                <h4>William Anderson</h4>
                <span>Content</span>
                <p>
                  Voluptas necessitatibus occaecati quia. Earum totam consequuntur qui porro et laborum toro des clara
                </p>
                <div className="social">
                  <Link to="/"><i className="bi bi-twitter"></i></Link>
                  <Link to="/"><i className="bi bi-facebook"></i></Link>
                  <Link to="/"><i className="bi bi-instagram"></i></Link>
                  <Link to="/"><i className="bi bi-linkedin"></i></Link>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section> */}


        </>
    )
}

export default Trainer
