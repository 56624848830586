import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Galleryimg from './GalleryApi';
import "./gallery.css"

const Gallery = () => {
    const [galleryData] = useState(Galleryimg);
    // console.log(Galleryimg)
    return (
        <>  
        <div className="breadcrumbs">
        <div className="container">
          <h2>Gallery</h2>
          <p>
          The cure for boredom is curiosty
          </p>
        </div>
      </div>
            <div className="container my-5">
            <div className="row row-cols-1 row-cols-md-3 g-4 mb-5">
            {galleryData.map((curElem) => {
                    return(
                        <div className="col gallery-img" key={curElem.id}>
                            <div className="card border-0 zoom-in-img">
                            <Link to="/gallery" >
                            <img src={curElem.image} className="card-img-top" style={{height:"35vh"}} alt={curElem.name} />
                            </Link>
                            </div>
                        </div>

                    )
                })
            }
            </div>
            </div>  
        </>
    )
}

export default Gallery
